/* Remove the background image from .timeline-container */
.timeline-container {
    background: none;
    margin: 0;
    min-height: 100vh;/* Add this to position the .timeline-blocks relative to .timeline-container */
}
.timeline-container h1{
    border-bottom: 2px solid #8C2E13; /* Orange underline for headings */
    display: inline;
  }
/* Add a new class for the background image */
.background-image {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1; /* Place the background image behind content */
}

.timeline-blocks {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    color: #000;
    font-family: Poppins;
    font-size: 21px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    
    z-index: 1; /* Place the content above the background image */
    background-repeat: no-repeat;
}

.timeline-block {
    margin: 10px;
    padding: 5px;
    background-image: url("../../assets/svg/timeline-svg/timeline.png");
    width: 100%;
    padding-left: 2%;
    background-repeat: no-repeat;
    background-position: center center;
}
