@import url(https://db.onlinewebfonts.com/c/4c19fc875e7ba1e6831129de3ab5ac0b?family=Retro+Gaming);
/* Sprint container */
.sprint-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 40px 0;
    padding: 20px;
    
  }
  
  /* Sprint text content */
  .sprint-text-content {
    flex: 1;
    padding: 20px;
    text-align: left;
    margin-left: 10%;
  }
  .sprint-text-content h2{
    border-bottom: 2px solid #8C2E13; /* Orange underline for headings */
    display: inline;
  }
  
  /* Sprint image */
  .sprint-image {
    flex: 1;
  }
  
  
  
  /* Button style */
  button {
    background-color: transparent;
    color: white;
    border: 1px solid white;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
  }

  .sprint-container2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    padding: 20px;
    margin-right: 20%;
  }
  
  .sprint-text-content2{
    text-align: center;
    font-size: 70px;
    color: white;
    height: 100%;
    margin-left: 20%;
    font-weight: bolder;
  }
  .text-below{
    font-size: x-large;
    font-weight: lighter;
  }
  
  .register-button{
    background-color: #DD8A35;
    color: white;
    border: 1px solid white;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    margin-top: 2%;
    width: 100%;
    font-family: "Retro Gaming";
  }