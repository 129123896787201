/* Coffee component styles */

.coffee-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 10%;
  }
  
  .coffee-text-content {
    flex: 1;
    padding: 20px;
    text-align: left;
  }
  .coffee-text-content h2 {
    border-bottom: 2px solid #8C2E13; /* Orange underline for headings */
    display: inline; /* Keep the heading inline with the underline */
  }
  .coffee-image {
    flex: 1;
    text-align: center;
  }
  
  .coffee-image img {
    max-width: 100%;
    max-height: 300px;
  }
  
  .coffee-right {
    order: 2; /* For image on the right */
  }
  .coffee-text-content.text-right {
    text-align: right;
  }