.leaderboard-container {
  text-align: center;
  align-content: center;
  align-items: center;
}

.leaderboard-set-heading {
  display: grid;
  grid-template-columns: 20% 60% 20%;
  margin: 10px 20vw;
  padding: 5px 20px;
}
.leaderboard-heading h1{
  margin-bottom: 0;
}
.leaderboard-hr{
    height: 0px;
    height: 0px;
    border: 1px solid #913B21;
    width: 220px;
    margin-bottom: 20px;
}
.leaderboard-set-heading div {
  background-color: #C16347;
  margin: 0 auto;
  padding: 10px 2vw;
  border-radius: 5.95821px;
  font-size: 0.7rem;
  text-transform: uppercase;
}
.contestent-name{
   text-align: left;
}
.leaderboard-btn-list{
    display: flex;
    flex-direction: row;
    text-align: center;
    align-content: center;
    align-items: center;
    margin: 10px 20vw;
    justify-content: center;
}
.leaderboard-btn-list div{
    padding: 10px;
    margin: 5px;
}
/* Mobile devices */
@media(max-width: 640px){
  .leaderboard-set-heading {
    margin: 10px 10vw;
  }
  .leaderboard-set-heading div {
    background-color: #606865;
    margin: 0 auto;
    padding: 10px 2vw;
    border-radius: 5.95821px;
    font-size: 0.7rem;
    text-transform: uppercase;
  }
  .leaderboard-set-heading {
    display: grid;
    grid-template-columns: 10% 80% 10%;
    margin: 10px 10vw;
    padding: 5px 20px;
  }
}