nav {
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 100;
    width: 100%;
    display: flex;
    justify-content: center;
    letter-spacing: 1px;
    padding: 0px 20px;
    transition: background-color 0.5s ease;
}

.navbar.scrolling {
    background-color: #F4F0E1;
}

.navbar.scrolling a {
    color: #000;
}

/* Navbar center links */
.nav-links {
    display: flex;
}

.nav-links div {
    margin: 20px;
}

a {
    text-decoration: none;
    color: white;
}

a:hover {
    color: cornsilk;
}

.navbar-register-btn {
    width: 140px;
    height: 37.739px;
    background-color: #F4F0E1;
    border-radius: 4px;
    border: 2px solid #FED2A5;
    cursor: pointer;
    margin-left: auto;
}

/* button */
.nav-btn {
    margin: 10px 0;
    padding: 0px 12px;
    color: white;
    background-color: #2b3531;
    border: 2.02899px solid #B04814;
    border-radius: 4px;
    line-height: 27px;
    text-align: center;
    text-transform: uppercase;
}

.nav-btn:hover {
    cursor: pointer;
    background-color: #333b38;
}

/* Mobile devices */
@media(max-width: 640px) {
    .nav-links {
        display: none;
    }
}