.leaderboardcard-container{
    display: grid;
    grid-template-columns: 20% 60% 20%;
    padding: 5px 20px;
    background: #913B21;
    border-radius: 5.95821px;
    align-items: center;
    text-align: center;
    align-content: center;
    margin: 10px 20vw;

}
.leaderboard-contestant{
    display: grid;
    grid-template-columns: 20% 80%;
    align-items: center;
    text-align: center;
    align-content: center;
    
}
.contestant-icon img{
    width: 30px;
    border-radius: 50%;

    
}
/* Mobile devices */
@media(max-width: 640px){
    .leaderboardcard-container{
        display: grid;
        grid-template-columns: 10% 80% 10%;
        padding: 5px 20px;
        margin: 10px 10vw;
    
    }
  }